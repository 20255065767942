import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = []

const content = () => (
  <div>
    <br />
    <h4>1. 주요 보도내용 (‘23.12.26 더일렉)</h4>
    스테이지파이브 제8기(2022년 1~12월) 감사보고서 상 자본잠식 상태라는 내용
    <br />
    <br />
    <h4>2. 설명 내용</h4>
    <p style={{ "line-height": "20px;" }}>
      - 상장신청을 위하여 2022년 한국채택국제회계기준으로 변경하면서, 그 동안
      <br />
      신주발행하였던 상환전환우선주(RCPS)의 회계처리가 일반기업 회계 기준에서는
      <br />
      자본 항목이었지만, 한국채택국제회계기준에서는 부채 항목으로 인식됨. 따라서
      <br /> 상환전환우선주 형태의 투자건을 제외한다면{" "}
      <strong>
        <span style={{ textDecoration: "underline" }}>
          일반기업회계기준으로는
          <br /> 자본잠식상태가 아님. 또한, 현금유출이 없는 회계상 평가손실에 불과함.
        </span>
      </strong>
    </p>
    <p>
      - 상환전환우선주 형태의 투자건은 국내외 유망한 대다수 스타트업의 일반적인
      <br /> 투자 형태임. 상환전환우선주 평가 시 발행한 회사의 기업가치가 커질수록
      <br /> 평가손실이 커지면서 부채 인식금액이 커지게 됨. 해당 부분은 상장전환우선주
      <br /> 투자금액이 클 수록 기업이 높은 가치로 평가받는다는 것을 의미함.
    </p>
    <p>
      - 스테이지파이브는 23년까지 통신 인프라와 기술개발에 투자 마무리 단계. 현재
      <br /> 기준 운영 효율화를 위한 재정비를 마친 바 있으며, 차기년도 영업익을 100%
      <br /> 개선하며 흑자로 턴어라운드 가능.
    </p>
    <p>
      - 신규주파수 신청 시 배포한 보도자료 내 밝힌 바와 같이, 재무적 투자자인
      <br /> 신한투자증권을 중심으로 대규모 투자 이어나갈 것.
    </p>
    <br />
  </div>
)

const press46 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 12월 26일 즉시배포 가능"
      pageInfo=""
      title="[보도설명자료] ‘23.12.26 더일렉 보도 관련 설명자료"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press46
